<template>
    <div class="title-wrap">
        <el-header style="height: 60px">
            <div class="left-header">
                <img class="topbar-logo" src="@/assets/logo.png" />
                <div class="head-header">
                <img class="name_logo" src="@/assets/name_logo.png" alt="" />
                </div>
            </div>
            <language class="language-option"></language>
        </el-header>
        <div class="unSubscribe">
            <h1>{{ $t('pubKnowledge.Unsubscribe') }}</h1>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item label="Email" label-width="100px" prop="email">
                    <div class="i_emial">
                        <el-input
                            id="email"
                            v-model="ruleForm.email"
                            class="email all"
                            :placeholder="$t('addLogin.forgetPsd.place1')"
                        /><i class="iconfont icon-email frontIcon"></i>
                    </div>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button type="primary" class="mybutton" @click="confirmUnSubscribe">{{$t('pubKnowledge.Unsubscribe')}}</el-button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import {deleteSubscribe} from '@/network/knowledge/index.js'
  import language from "@/components/language";
  export default {
    components: {language},
    props: {
    },
    data() {
      var validateEamil = (rule, value, callback) => {
        let emailRegExp = /^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (value === '') {
          callback(new Error(this.$t('pubKnowledge.inputEamil')));
        } else if(!emailRegExp.test(value)){
          callback(new Error(this.$t('pubKnowledge.checkEmail')));        
        }else{
          callback();
        }
      };
      return {
        dialogVisible:false,
        ruleForm:{
          email:'',
        },
        rules: {
          email:{validator:validateEamil,trigger: 'blur'}
        }
      };
    },
    watch: {},
    mounted() {
    },
    methods: {
      confirmUnSubscribe(){
        this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              let params = {
                subscribeId: this.$route.query.subscribeId,
                email: this.ruleForm.email
              }
              deleteSubscribe(params).then(res=>{
                this.$message({
                    message: this.$t('pubKnowledge.UnsubscribeSuccessful'),
                    type: "success",
                });
                this.ruleForm = {
                  email:'',
                }
              })
            } else {
              return false;
            }
          });
      },
    },
  };
  </script>
  
<style scoped lang="scss">
.language-option {
  position: absolute;
  top: 20px;
  right: 20px;
}
.title-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(240, 242, 245, 1);
  .left-header {
    background: linear-gradient(
      90deg,
      rgba(0, 76, 170, 1) 34%,
      rgba(3, 122, 188, 1) 100%
    );
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    .topbar-logo {
      padding-left: 10px;
      padding-right: 17px;
      padding-top: 7px;
      max-height: 100%;
      max-width: 100%;
    }
    .name_logo {
      width: 231px;
      height: 36px;
    }
  }
  
}
.unSubscribe{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-320px, -144px);
    width: 640px;
    height: 288px;
    text-align: center;
    background-color: white;
    .demo-ruleForm{
        width: 100%;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .dialog-footer{
        align-self: end;
        margin-right: 40px;
        .mybutton{
            height: 40px;
        }
    }
}
::v-deep .el-form-item__label {
    font-size: 16px !important;
}
.i_emial {
    width: 500px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.email {
    width: 500px;
    height: 40px;
    outline: none;
    font-size: 16px;
}
::v-deep .el-input__inner{
    padding-left: 40px;
}
input::-webkit-input-placeholder {
    padding-left: 15px;
    font-size: 16px;
    color: rgba(195, 195, 195, 1);
    letter-spacing: 2px;
}
.frontIcon {
    position: absolute;
    color: rgba(195, 195, 195, 1);
    font-size: 22px;
    margin: 25px auto;
    margin-left: 9px;
  }
</style>
  